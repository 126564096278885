<template>
  <div>
    <ul class="footer-nav">
      <li><a href="#">FAQs</a> &nbsp;| &nbsp;</li>
      <li><a href="#">Disclaimer</a> &nbsp;| &nbsp;</li>
      <li><a href="#">Results Sample</a></li>
      &nbsp;| &nbsp;
      <li>
        <router-link v-if="user" to="/dashboard">Dashboard</router-link>
        <router-link v-else to="/login?redirect=dashboard">Login</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { onAuthStateChanged } from "@/api/authApi";
export default {
  data() {
    return {
      user: null,
    };
  },
  async created() {
    const user = await onAuthStateChanged();
    if (user) {
      this.user = user;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>